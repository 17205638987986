import React, {
  useState,
  useEffect,
  useContext
} from "react";
import "./landing.scss"
import { AppContext } from "appContext";
import { useQuery } from "@apollo/react-hooks";
import query from "./query";

import Modal from "react-modal";

const customStyles = {
  overlay: {
    padding: "2em",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "rgba(255,255,255,0.8)",
    zIndex: 11
  },
  content: {
    padding: 56,
    width: "100%",
    maxWidth: 800,
    maxHeight: `${80 * (16 / 9)}vh`,
    margin: "auto",
    backgroundColor: "var(--green)",
    color: "var(--white)",
    borderRadius: 8,
    position: "relative"
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const Landing = ({
  header,
}) => {
  const [open, setOpen] = useState(false);
  return <section className={"Landing-wrapper"}>
    <header className={"Landing-head"}>
      <h1 className={"Landing-header"}>{header}</h1>
    </header>
    <div className={"Landing-links"}>
      <button
        title="go to log in page"
        className={"Landing-button"}
        aria-label={"modal toggle button"}
        onClick={() => setOpen(true)}
      >
        Sign in
      </button>
    </div>

    <Overlay
      open={open}
      setOpen={setOpen}
    />
  </section>
}

const Overlay = ({
  open,
  setOpen
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState(null);
  const loginContext = useContext(AppContext);

  const { data } = useQuery(query, {
    variables: {
      heading: username,
      username: username
    },
  });

  useEffect(() => {
    setErrors(null)
  }, [open])

  const handleSubmit = async e => {
    e.preventDefault();

    if (!password.length) {
      setErrors("Please enter a password.")
    } else if (!username.length) {
      setErrors("Please enter a username.")
    } else {
      setSubmitted(true)
    }
  }

  useEffect(() => {
    if (!submitted) return

    let allUsers = [...data.users, ...data.entries]

    if (allUsers.length) {
      if (password === data.globalSet.password) {
        let user = data.entries && data.entries.length ? data.entries[0] : (data.users & data.users.length ? data.users & data.users[0] : null)
        loginContext.setLoggedIn(true)
        loginContext.setUser(user)
        // loginContext.login()
        setErrors(null)
      } else {
        loginContext.setLoggedIn(false)
        setErrors("Wrong username or password.")
      }
    } else {
      loginContext.setLoggedIn(false)
      setErrors("Wrong username or password.")
    }
  }, [data, submitted, password, loginContext])

  const close = () => setOpen(false);
  return (
    <div>
      <Modal
        className="Modal"
        isOpen={open}
        onRequestClose={close}
        style={customStyles}
        contentLabel="Login"
      >
        <button className="Modal-close" onClick={close}>
          close
        </button>
        <h2 className="Modal-heading">Welcome!</h2>
        <p className="Modal-copy">Enter your login credentials below to log-in to the intranet.</p>
        <form>
          <div className={"Modal-form-wrap"}>
            <input
              placeholder="Username"
              className="Modal-form-input"
              type="text"
              value={username}
              onChange={e => setUsername(e.target.value)}
              required={true}
            />
            <input
              placeholder="Password"
              className="Modal-form-input"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required={true}
            />
          </div>
          {errors && <div className="Modal-errors">
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11" cy="11" r="11" fill="#FFC63E" />
              <path d="M11.9635 12.2606H10.1925L9.8999 7.3326V5.5H12.2407V7.348L11.9635 12.2606ZM12.1329 16.1106H9.9615V13.5542H12.1329V16.1106Z" fill="#005B2C" />
            </svg>
            <p className={"Modal-errormsg"}>{errors}</p>
          </div>}
          <button
            onClick={handleSubmit}
            className="Modal-form-submit"
          >Login</button>
        </form>


      </Modal>
    </div>
  );
}

export default Landing